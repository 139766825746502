@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none;
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}

.text-first-uppercase:first-letter {
  text-transform: uppercase;
}

.sb-show-main {
  padding: 0 !important;
}

/* FullScreenContainer */

.fullScreenContainer-bg {
  position: absolute;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.fullScreenContainer-bg-default {
  background-image: url('/assets/pattern_grey.svg');
  width: 100%;
}

.fullScreenContainer-bg-left {
  background-image: url('/assets/bg_img_login.jpg');
  width: 50%;
  left: 0;
}
.fullScreenContainer-bg-right {
  background-image: url('/assets/pattern_color.svg');
  width: 50%;
  right: 0;
}

.hover\:bg-white-op-10:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.shadow-marketing-blue {
  box-shadow: 0 10px 25px 0 rgba(40, 110, 251, 0.2);
}
